<!--This component (step 5) for personnel informations-->
<template>
  <v-card-text>
    <div class="headline font-weight-bold ml-2 mb-12 titleColor--text">
      {{ $t("generic.lang_personnal_info") }}
    </div>

    <!--Start personnel informations form-->
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container>
        <v-row>
          <!--*****************-->

          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              v-model="first_name"
              :rules="[rules.required]"
              :label="$t('generic.lang_first_name')"
              outlined
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              v-model="last_name"
              :rules="[rules.required]"
              :label="$t('generic.lang_last_name')"
              outlined
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              v-model="phone"
              :label="$t('generic.lang_phone')"
              outlined
              :rules="[rules.required]"
              type="Number"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              v-model="email"
              :label="$t('generic.lang_email')"
              outlined
              :rules="[rules.required, rules.email]"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-textarea
              v-model="note"
              :label="$t('generic.lang_note')"
              outlined
              rows="8"
            />
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <h4 class="mb-4 font-weight-regular text-md-body-1">
              {{ $t("generic.lang_note_file") }}
            </h4>

            <v-sheet
              width="100%"
              class="justify-center"
              style="cursor: pointer; border: 2px dashed grey;"
              @click="onFileClick()"
            >
              <v-file-input
                v-model="attachment"
                id="fileUpload"
                style="display: none;"
                accept="image/png, image/jpg, image/jpeg, .pdf, .doc, .docx, .xsl, .txt"
              />

              <v-container grid-list-xs fill-height align-center justify-center>
                <v-row class="my-2" justify="center">
                  <v-icon color="grey" size="55">
                    mdi-cloud-upload-outline
                  </v-icon>
                </v-row>

                <v-row
                  v-if="this.attachmentName"
                  class="my-2 d-flex align-center"
                  justify="center"
                >
                  <h3 class="grey--text">
                    {{ this.attachmentName }}
                  </h3>

                  <v-btn flat icon color="primary" @click="clearAttachment()">
                    <v-icon color="black">mdi-close</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-sheet>

            <!--<v-file-input
              v-model="attachment"
              accept="image/png, image/jpg, image/jpeg, .pdf, .doc, .docx, .xsl, .txt"
              placeholder="No File"
              outlined
              show-size
              truncate-length="50"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
            />-->
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-checkbox
              v-model="checkbox"
              :rules="[rules.checkbox]"
              required
              color="blue"
            >
              <template v-slot:label>
                <div @click.stop="">
                  {{ $t("generic.lang_do_you_accept_the") }}

                  <router-link to="/terms" target="_blank" class="info--text">
                    {{ $t("generic.lang_terms") }}
                  </router-link>

                  {{ $t("generic.lang_and") }}

                  <router-link
                    to="/conditions"
                    target="_blank"
                    class="info--text"
                  >
                    {{ $t("generic.lang_conditions") }}
                  </router-link>
                </div>
              </template>
            </v-checkbox>
          </v-col>

          <!--*****************-->
        </v-row>
      </v-container>
    </v-form>
    <!--End personnel informations form-->
  </v-card-text>
</template>

<script>
export default {
  name: "Step5Component",

  data() {
    return {
      attachment: [],
      attachmentName: localStorage.getItem("attachmentName") || "",

      valid: true,

      rules: {
        required: (value) => !!value || "Required.",
        first_name: (value) =>
          value.length <= 10 || "First Name must be less than 10 characters",
        last_name: (value) =>
          value.length <= 10 || "Last Name must be less than 10 characters",
        phone: (value) =>
          value.length <= 10 || "Phone must be less than 10 characters",
        //(value) => !isNaN(value),
        email: (value) => /.+@.+/.test(value) || "E-mail must be valid",
        checkbox: (value) => !!value || "You must agree to continue!",
      },
    };
  },

  computed: {
    first_name: {
      get() {
        return this.$store.state.customerData.first_name;
      },
      set(val) {
        this.$store.commit("customerData/addFirstName", val);
      },
    },
    last_name: {
      get() {
        return this.$store.state.customerData.last_name;
      },
      set(val) {
        this.$store.commit("customerData/addLastName", val);
      },
    },
    phone: {
      get() {
        return this.$store.state.customerData.phone;
      },
      set(val) {
        this.$store.commit("customerData/addPhone", val);
      },
    },
    email: {
      get() {
        return this.$store.state.customerData.email;
      },
      set(val) {
        this.$store.commit("customerData/addEmail", val);
      },
    },
    note: {
      get() {
        return this.$store.state.customerData.note;
      },
      set(val) {
        this.$store.commit("customerData/addNote", val);
      },
    },
    checkbox: {
      get() {
        return this.$store.state.customerData.checkbox;
      },
      set(val) {
        this.$store.commit("customerData/addCheckbox", val);
      },
    },
  },

  watch: {
    attachment: function(val) {
      localStorage.setItem("attachmentName", val.name);
      this.attachmentName = val.name;

      this.toBase64(val)
        .then((data) => {
          let file = data.slice(data.indexOf(",") + 1);
          this.$store.commit("customerData/addAttachment", file);
        })
        .catch((err) => {
          this.$swal({
            title: "Error",
            icon: "error",
            text: err.message,
          });
        });
    },
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    onFileClick() {
      document.getElementById("fileUpload").click();
    },

    clearAttachment() {
      this.$store.commit("customerData/addAttachment", "");

      localStorage.removeItem("attachmentName");
      this.attachmentName = "";
    },
  },
};
</script>
