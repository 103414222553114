<!--This component (step 6) to confirm reservation-->
<template>
  <div class="my-16 py-16 text-center">
    <div class="my-5">
      <!--loader-->
      <div class="text-center">
        <v-progress-circular
          indeterminate
          v-if="loading"
          color="primary"
          size="128"
        />
      </div>

      <div>
        <p>
          {{ $t("generic.lang_order_successfull_msg") }} {{ date }}
          {{ $t("generic.lang_with") }} {{ time }} .
        </p>
        <p class="subtitle-2">
          {{ $t("generic.lang_for") }} {{ numberPersons }}
          {{ $t("generic.lang_people") }} ({{ reservationTypeName }}).
        </p>
      </div>

      <!--Start Confirm Button-->
      <div class="my-16 text-center">
        <v-btn
          color="primary"
          tile
          x-large
          outlined
          :disabled="overlay"
          @click="sendReservationData()"
        >
          {{ $t("generic.lang_confirm_booking") }}
        </v-btn>

        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </div>
      <!--End Confirm Button-->

      <!--Start cancel and edit Button-->
      <div class="d-flex mx-auto">
        <div class="mx-auto d-flex">
          <div class="mr-3">
            <v-btn
              class="ma-2"
              outlined
              fab
              text
              dark
              color="primary"
              @click="editBtn()"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <p>{{ $t("generic.lang_edit") }}</p>
          </div>

          <div class="ml-3">
            <v-btn
              class="ma-2"
              outlined
              fab
              text
              dark
              color="primary"
              @click="cancelBtn()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <p>{{ $t("generic.lang_cancel") }}</p>
          </div>
        </div>
      </div>
      <!--End cancel and edit Button-->
    </div>
  </div>
</template>

<script>
/*import plugins*/
import { createNamespacedHelpers } from "vuex";

export default {
  name: "Step6Component",

  data: () => ({
    overlay: false,
  }),

  computed: {
    //map loader getters
    ...createNamespacedHelpers("loader").mapGetters(["loading"]),
    ...createNamespacedHelpers("reservationTypes").mapState([
      "numberPersons",
      "reservationTypeName",
    ]),
    ...createNamespacedHelpers("dateTime").mapState(["date", "time"]),
  },

  watch: {
    loading(val) {
      if (!val) {
        this.overlay = false;
      }
    },
  },

  methods: {
    sendReservationData() {
      this.overlay = true;

      this.$store
        .dispatch("order/createReservation")
        .then(() => {
          window.store.commit("order/isOrder", true);
          this.$router.push("/booking-receipt");
        })
        .catch((err) => {
          this.$swal({
            title: "Error",
            icon: "error",
            text: err.message,
            //text: err.response.data.message,
          });
        });
    },

    cancelBtn() {
      window.indexedDB.deleteDatabase("localforage");
      window.localStorage.clear();

      this.$router.push("/").then(() => window.location.reload());
    },

    editBtn() {
      return this.$router.push("/");
    },
  },
};
</script>
