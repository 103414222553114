var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('div',{staticClass:"headline font-weight-bold mb-16 titleColor--text"},[_vm._v(" "+_vm._s(_vm.$t("generic.lang_tables_title"))+" ")]),_c('div',{staticClass:"text-center"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"128"}}):_vm._e()],1),_c('v-alert',{attrs:{"type":_vm.alertType,"text":_vm.alertType == 'error' ? true : false,"outlined":"","icon":"mdi-alert"}},[_vm._v(" "+_vm._s(!_vm.errorMsg ? this.$t("generic.lang_tables_wrong_title") : this.$t("generic.lang_tables_error_title") + " " + this.numberPersons)+" ")]),(
            (Array.isArray(this.rooms.length == 0) &&
              this.rooms.length == 0) ||
              this.rooms == null
          )?_c('v-sheet',{staticClass:"py-16 my-16 text-center",attrs:{"elevation":"0","outlined":""}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("generic.lang_no_table_available"))+" ")])]):_c('div',[_c('v-tabs',{attrs:{"background-color":"secondary","active-class":"primary white--text","hide-slider":"","grow":"","show-arrows":"","center-active":"","centered":""},model:{value:(_vm.roomSelected),callback:function ($$v) {_vm.roomSelected=$$v},expression:"roomSelected"}},_vm._l((_vm.rooms),function(room){return _c('v-tab',{key:room.id},[_vm._v(" "+_vm._s(room.name)+" ")])}),1),_c('v-tabs-items',{staticClass:"pt-6",model:{value:(_vm.roomSelected),callback:function ($$v) {_vm.roomSelected=$$v},expression:"roomSelected"}},_vm._l((_vm.rooms),function(room){return _c('v-tab-item',{key:room.id},[_c('v-item-group',{attrs:{"active-class":"primary","multiple":""},model:{value:(_vm.selectedTables),callback:function ($$v) {_vm.selectedTables=$$v},expression:"selectedTables"}},[_c('v-container',[_c('v-row',_vm._l((room.tables),function(roomTable){return _c('v-col',{key:roomTable.uuid,attrs:{"xs":"6","sm":"4","md":"3","lg":"2","align":"center","justify":"center"}},[_c('v-item',{attrs:{"value":roomTable.uuid},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var active = ref.active;
          var toggle = ref.toggle;
return [_c('v-card',{staticClass:"align-center",attrs:{"color":active ? 'primary' : 'secondary',"elevation":"0"},on:{"click":toggle}},[_c('div',{staticClass:"pt-8 pb-1"},[_c('span',[_vm._v(_vm._s(roomTable.name))]),_c('br'),_c('v-chip',{staticClass:"mt-1",attrs:{"color":"grey lighten-4"}},[_vm._v(" "+_vm._s(roomTable.maxPersons)+" "+_vm._s(_vm.$t("generic.lang_persons"))+" ")])],1)])]}}],null,true)})],1)}),1)],1)],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }