<template>
  <!--Start Steps Page-->
  <!--this page contain steps from step 2 to step 6-->
  <v-container
    fill-height
    :class="
      this.$route.path == '/steps/4'
        ? 'ma-0 overflow-hidden mx-auto justify-center'
        : 'pa-0 ma-0 overflow-hidden mx-auto justify-center'
    "
  >
    <v-row class="mx-auto justify-center">
      <!--Start Top Bar Of Stepts-->
      <v-col cols="12" xs="12" sm="10" md="10" lg="8" class="py-0 mt-8 mb-4">
        <top-bar-component :step="this.step" :progressVal="this.progressVal" />
      </v-col>
      <!--End Top Bar Of Stepts-->

      <v-col cols="12" xs="12" sm="10" md="10" lg="8">
        <!--Start Steps Card-->
        <v-card elevation=" 0" tile outlined width="100%" class="my-2">
          <v-window v-model="step" touchless>
            <!--Start step 2-->
            <v-window-item :value="2">
              <step-2-component />
            </v-window-item>
            <!--End step 2-->

            <!--Start step 3-->
            <v-window-item :value="3">
              <step-3-component ref="step3" />
            </v-window-item>
            <!--End step 3-->

            <!--Start step 4-->
            <v-window-item :value="4">
              <step-4-component />
            </v-window-item>
            <!--End step 4-->

            <!--Start step 5-->
            <v-window-item :value="5">
              <step-5-component ref="step5" />
            </v-window-item>
            <!--End step 5-->

            <!--Start step 6-->
            <v-window-item :value="6">
              <step-6-component />
            </v-window-item>
            <!--End step 6-->
          </v-window>

          <!--Start Next, Back and Skip Button-->
          <v-card-actions v-if="step !== 6" class="px-4 py-2">
            <v-btn
              outlined
              large
              tile
              color="warning"
              class="px-7"
              @click="prev()"
            >
              {{ $t("generic.lang_back") }}
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              large
              tile
              class="px-7"
              color="success"
              depressed
              :disabled="!isNextBtn"
              @click="next(step)"
            >
              {{
                nextAndSkip == "SKIP"
                  ? $t("generic.lang_skip")
                  : $t("generic.lang_next")
              }}
            </v-btn>
          </v-card-actions>
          <!--End Next, Back and Skip Button-->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <!--End Steps Page-->
</template>

<script>
import TopBarComponent from "@/components/stepsBar/TopBarComponent.vue";
import Step2Component from "@/components/steps/Step2Component.vue";
import Step3Component from "@/components/steps/Step3Component.vue";
import Step4Component from "@/components/steps/Step4Component.vue";
import Step5Component from "@/components/steps/Step5Component.vue";
import Step6Component from "@/components/steps/Step6Component.vue";

/*import plugins*/
import { createNamespacedHelpers } from "vuex";

export default {
  name: "Steps",

  components: {
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
    Step6Component,
    TopBarComponent,
  },

  data: () => ({
    step: 2,
    progressVal: 0,
  }),

  computed: {
    ...createNamespacedHelpers("reservationTypes").mapGetters([
      "reservationTypes",
      "reservationTypeUuid",
      "skipTables",
      "skipItems",
    ]),

    ...createNamespacedHelpers("config").mapGetters(["isNextBtn"]),
    ...createNamespacedHelpers("tables").mapState(["tables"]),
    ...createNamespacedHelpers("items").mapState(["selectedItems"]),

    nextAndSkip() {
      if (
        (!this.tables.length && this.step == 3) ||
        (!this.selectedItems.length && this.step == 4)
      ) {
        return "SKIP";
      } else {
        return "NEXT";
      }
    },
  },

  watch: {
    "$route.params.step": function(val) {
      if (val == 2) {
        this.step = 2;
        this.progressVal = 20;
        this.$store.dispatch("dateTime/fetchOpenTimes");
      } else if (val == 3) {
        this.step = 3;
        this.progressVal = 40;
        this.$store.dispatch("tables/fetchRooms");
      } else if (val == 4) {
        this.step = 4;
        this.progressVal = 60;
        this.$store.dispatch("items/fetchMenuTypes");
      } else if (val == 5) {
        this.step = 5;
        this.progressVal = 80;
      } else if (val == 6) {
        this.step = 6;
        this.progressVal = 100;
      } else {
        return;
      }
    },
  },

  created: function() {
    if (this.$route.params.step == 2) {
      this.step = 2;
      this.progressVal = 20;
      this.$store.dispatch("dateTime/fetchOpenTimes");
    } else if (this.$route.params.step == 3) {
      this.step = 3;
      this.progressVal = 40;
      this.$store.dispatch("tables/fetchRooms");
    } else if (this.$route.params.step == 4) {
      this.step = 4;
      this.progressVal = 60;
      this.$store.dispatch("items/fetchMenuTypes");
    } else if (this.$route.params.step == 5) {
      this.step = 5;
      this.progressVal = 80;
    } else if (this.$route.params.step == 6) {
      this.step = 6;
      this.progressVal = 100;
    } else {
      return;
    }
  },

  methods: {
    next(val) {
      if (val == 2) {
        if (this.skipItems && this.skipTables) {
          this.step = 5;
          this.$router.push({ name: "steps", params: { step: "5" } });
        } else if (this.skipTables) {
          this.step = 4;
          this.$router.push({ name: "steps", params: { step: "4" } });
        } else {
          this.step = 3;
          this.$router.push({ name: "steps", params: { step: "3" } });
        }
        return;
      }

      if (val == 3) {
        if (this.validStep3()) {
          if (this.skipItems) {
            this.step = 5;
            this.$router.push({ name: "steps", params: { step: "5" } });
          } else {
            this.step = 4;
            this.$router.push({ name: "steps", params: { step: "4" } });
          }
        }
        return;
      }

      if (val == 4) {
        this.step = 5;
        this.$router.push({ name: "steps", params: { step: "5" } });
        return;
      }

      if (val == 5) {
        if (this.validStep5()) {
          this.step = 6;
          this.$router.push({ name: "steps", params: { step: "6" } });
        }
        return;
      }
    },

    prev() {
      this.$router.go(-1);
    },

    validStep3() {
      if (!this.$refs.step3.checkMaxPersons()) {
        return false;
      } else {
        return true;
      }
    },

    validStep5() {
      if (!this.$refs.step5.validate()) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
