<!--This component (step 2) to choose reservation date and time-->
<template>
  <v-card-text>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <div class="headline font-weight-bold mb-5 titleColor--text">
          {{ $t("generic.lang_date_time_title") }}
        </div>
      </v-col>

      <!--Start DatePicker-->
      <v-col cols="12" xs="12" sm="12" md="5" lg="5">
        <div class="pb-3 font-weight-regular">
          {{ $t("generic.lang_select_date") }}
        </div>

        <v-card elevation="0" tile class="pt-2">
          <v-date-picker
            v-model="date"
            color="primary"
            no-title
            full-width
            :allowed-dates="allowedDates"
            :min="new Date().toISOString()"
          />
        </v-card>
      </v-col>
      <!--End DatePicker-->

      <!--Start Times-->
      <v-col cols="12" xs="12" sm="12" md="7" lg="7">
        <div class="pb-3 font-weight-regular">
          {{ $t("generic.lang_select_time") }}
        </div>

        <div class="text-center">
          <v-progress-circular
            indeterminate
            v-if="loading"
            color="primary"
            size="128"
          />
        </div>

        <v-card v-if="this.times.length != 0" elevation="0" tile>
          <v-chip-group v-model="time" active-class="primary" column mandatory>
            <div align="center" justify="start">
              <v-chip
                v-for="(time, i) in times"
                :key="i"
                :value="time"
                large
                label
                tile
                color="rgb(248, 247, 247)"
              >
                {{ time }}
              </v-chip>
            </div>
          </v-chip-group>
        </v-card>

        <v-card v-else elevation="0" tile class="text-center pt-15">
          <div>
            <p v-show="!loading">
              {{ $t("generic.lang_no_time_available") }}
            </p>
          </div>
        </v-card>
      </v-col>
      <!--End Times-->

      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        v-if="this.$vuetify.breakpoint.mdAndUp"
      >
        <div class="my-16"></div>
      </v-col>

      <!--***-->
    </v-row>
  </v-card-text>
</template>

<script>
/*import plugins*/
import { createNamespacedHelpers } from "vuex";

export default {
  name: "Step2Component",

  computed: {
    ...createNamespacedHelpers("loader").mapGetters(["loading"]),
    ...createNamespacedHelpers("dateTime").mapGetters([
      "openDaysIndex",
      "times",
    ]),

    date: {
      get() {
        return this.$store.state.dateTime.date;
      },
      set(value) {
        this.$store.commit("dateTime/addDate", value);
        this.times;
      },
    },

    time: {
      get() {
        return this.$store.state.dateTime.time;
      },
      set(value) {
        this.$store.commit("dateTime/addTime", value);
      },
    },
  },

  methods: {
    allowedDates(val) {
      for (let index = 0; index < this.openDaysIndex.length; index++) {
        let dayIndex =
          this.openDaysIndex[index] > 0
            ? eval(this.openDaysIndex[index] - 1)
            : this.openDaysIndex[index];

        if (dayIndex == new Date(val).getDay()) {
          return new Date(val).getDay() === dayIndex;
        }
      }
    },

    //allowedDates: (val) => ![1, 2].includes(new Date(val).getDay()),
    //allowedDates: (val) => new Date(val).getDay() === 3 ||
  },
};
</script>

<style>
.v-date-picker-table {
  background-color: rgb(248, 247, 247);
}

.v-date-picker-header {
  background-color: rgb(248, 247, 247);
}

.v-btn {
  /*background-color: rgb(250, 179, 145);*/
}
</style>
