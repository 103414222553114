<!--This component (step 3) to choose the available tables-->
<template>
  <v-card-text>
    <v-row>
      <!--Start Available Tables-->
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card elevation="0">
          <div class="headline font-weight-bold mb-16 titleColor--text">
            {{ $t("generic.lang_tables_title") }}
          </div>

          <!--loader-->
          <div class="text-center">
            <v-progress-circular
              indeterminate
              v-if="loading"
              color="primary"
              size="128"
            />
          </div>

          <v-alert
            :type="alertType"
            :text="alertType == 'error' ? true : false"
            outlined
            icon="mdi-alert"
          >
            {{
              !errorMsg
                ? this.$t("generic.lang_tables_wrong_title")
                : this.$t("generic.lang_tables_error_title") +
                  " " +
                  this.numberPersons
            }}
          </v-alert>

          <v-sheet
            v-if="
              (Array.isArray(this.rooms.length == 0) &&
                this.rooms.length == 0) ||
                this.rooms == null
            "
            elevation="0"
            outlined
            class="py-16 my-16 text-center"
          >
            <p>
              {{ $t("generic.lang_no_table_available") }}
            </p>
          </v-sheet>

          <div v-else>
            <v-tabs
              v-model="roomSelected"
              background-color="secondary"
              active-class="primary white--text"
              hide-slider
              grow
              show-arrows
              center-active
              centered
            >
              <v-tab v-for="room in rooms" :key="room.id">
                {{ room.name }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="roomSelected" class="pt-6">
              <v-tab-item v-for="room in rooms" :key="room.id">
                <v-item-group
                  v-model="selectedTables"
                  active-class="primary"
                  multiple
                >
                  <v-container>
                    <v-row>
                      <v-col
                        xs="6"
                        sm="4"
                        md="3"
                        lg="2"
                        v-for="roomTable in room.tables"
                        :key="roomTable.uuid"
                        align="center"
                        justify="center"
                      >
                        <v-item
                          v-slot="{ active, toggle }"
                          :value="roomTable.uuid"
                        >
                          <v-card
                            :color="active ? 'primary' : 'secondary'"
                            class="align-center"
                            elevation="0"
                            @click="toggle"
                          >
                            <div class="pt-8 pb-1">
                              <span>{{ roomTable.name }}</span>
                              <br />
                              <v-chip color="grey lighten-4" class="mt-1">
                                {{ roomTable.maxPersons }}
                                {{ $t("generic.lang_persons") }}
                              </v-chip>
                            </div>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-item-group>
              </v-tab-item>
            </v-tabs-items>
          </div>

          <!---->
        </v-card>
      </v-col>
      <!--End Available Tables-->
    </v-row>
  </v-card-text>
</template>

<script>
/**
 * import plugins
 */
import { createNamespacedHelpers } from "vuex";

export default {
  name: "Step3Component",

  data() {
    return {
      alertType: "warning",
      errorMsg: false,

      roomSelected: 0,

      sumMaxPersons: 0,
    };
  },

  computed: {
    //map loader getters
    ...createNamespacedHelpers("loader").mapGetters(["loading"]),
    ...createNamespacedHelpers("tables").mapGetters(["rooms"]),
    ...createNamespacedHelpers("reservationTypes").mapState(["numberPersons"]),

    selectedTables: {
      get() {
        return this.$store.state.tables.tables;
      },
      set(val) {
        this.$store.commit("tables/addTables", val);
      },
    },
  },

  methods: {
    checkMaxPersons() {
      if (
        Array.isArray(this.selectedTables) &&
        this.selectedTables.length != 0 &&
        this.selectedTables != null
      ) {
        this.sumMaxPersons = 0;
        var tableMaxPersons = 0;

        this.rooms.forEach((room) => {
          room.tables.forEach((roomTable) => {
            /***********/
            this.selectedTables.forEach((selectedTable) => {
              if (roomTable.uuid == selectedTable) {
                tableMaxPersons = roomTable.maxPersons;

                this.sumMaxPersons = eval(this.sumMaxPersons + tableMaxPersons);
              }
            });
            /***********/
          });
        });

        if (this.sumMaxPersons >= this.numberPersons) {
          return true;
        } else {
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

          this.alertType = "error";
          this.errorMsg = true;

          return false;
        }
      } else {
        return true;
      }
    },
  },
};
</script>
