<template>
  <!--Start Card Item-->
  <div>
    <v-row v-if="(Array.isArray(items) && items.length == 0) || items == null">
      <v-col cols="12">
        <v-card elevation="0" outlined tile class="py-15 text-center">
          <p>
            {{ $t("generic.lang_no_items_available") }}
          </p>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        class="mt-2 flex d-flex flex-column"
        v-for="item in items"
        :key="item.id"
      >
        <v-card
          elevation="1"
          outlined
          tile
          class="text-center flex d-flex flex-column justify-space-between"
        >
          <v-card-text class="d-flex justify-space-around">
            <div>
              <v-avatar size="160">
                <img :src="item.image" alt="" />
              </v-avatar>
            </div>

            <div style="position: absolute; top: 0;" class="primary">
              <span class="font-weight-bold body-2 white--text pa-2">
                {{
                  item.sellingPrice &&
                    item.sellingPrice.substring(
                      0,
                      item.sellingPrice.indexOf(".") + 2
                    ) | currency
                }}
                <!--.toFixed(2)-->
              </span>
            </div>
          </v-card-text>

          <v-card-text class="pb-0 pt-1">
            <div>
              <h3 class="primary--text" height="500px">
                {{ item.name }}
              </h3>
            </div>

            <div class="mt-3" height="500px">
              <h5>
                {{ item.description && item.description.substring(0, 50) }}
              </h5>
            </div>
          </v-card-text>

          <v-card-text id="qty" class="py-0 d-inline-flex justify-center">
            <v-btn small icon @click="minus(item.id)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>

            <div class="mx-3">
              <h3>{{ quantity(item.id) }}</h3>
            </div>

            <v-btn
              small
              icon
              @click="yourPlat(item.id, item.name, item.description)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-text>

          <v-card-actions>
            <v-btn
              large
              depressed
              tile
              color="primary"
              class="mx-auto"
              block
              :disabled="quantity(item.id) > 0"
              @click="yourPlat(item.id, item.name, item.description)"
            >
              <v-icon v-if="quantity(item.id) > 0">mdi-check</v-icon>
              <span v-else>{{ $t("generic.lang_add_to_plat") }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <!--End Card Item-->
</template>

<script>
/*import plugins*/
import { createNamespacedHelpers } from "vuex";

export default {
  name: "ItemCard",

  data() {
    return {
      count: 1,
    };
  },

  computed: {
    ...createNamespacedHelpers("items").mapGetters(["quantity", "items"]),
  },

  methods: {
    ...createNamespacedHelpers("items").mapMutations(["minus"]),

    //add selected items
    yourPlat(itemId, itemName, itemDescription) {
      let selectedItem = {
        itemId: itemId,
        itemName: itemName,
        itemDescription: itemDescription,
      };
      this.$store.commit("items/addSelectedItems", selectedItem);
    },
  },
};
</script>
