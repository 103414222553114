<!--This component (step 4) to choose items-->
<template>
  <v-card-text>
    <!--Start menu-->

    <div class="headline font-weight-bold mb-16 titleColor--text">
      {{ $t("generic.lang_menu_title") }}
    </div>

    <!--loader-->
    <div class="text-center">
      <v-progress-circular
        indeterminate
        v-if="loading"
        color="primary"
        size="120"
      />
    </div>

    <div>
      <v-item-group v-model="menuTypeSelected">
        <v-tabs
          v-model="tab"
          background-color="secondary"
          active-class="primary white--text"
          hide-slider
          grow
          show-arrows
          center-active
          centered
        >
          <v-item
            v-for="(menuType, n) in menuTypes"
            :key="`menu-${n}`"
            :value="menuType.uuid"
            v-slot="{ active, toggle }"
          >
            <v-tab
              @click="toggle"
              :color="active ? 'secondary' : 'secondary'"
              :disabled="loading"
            >
              {{ menuType.name }}
            </v-tab>
          </v-item>
        </v-tabs>
      </v-item-group>

      <v-window v-model="menuTypeSelected">
        <v-window-item
          v-for="(menuType, n) in menuTypes"
          :key="`item-${n}`"
          :value="menuType.uuid"
        >
          <!--start item card component-->
          <item-card-component />
          <!--end item card component-->
        </v-window-item>
      </v-window>
    </div>

    <!--End menu-->
  </v-card-text>
</template>

<script>
import ItemCardComponent from "@/components/itemCard/ItemCardComponent.vue";

/*import plugins*/
import { createNamespacedHelpers } from "vuex";

export default {
  name: "Step4Component",

  components: {
    ItemCardComponent,
  },

  data() {
    return {
      tab: 0,
    };
  },

  computed: {
    //map loader getters
    ...createNamespacedHelpers("loader").mapGetters(["loading"]),
    ...createNamespacedHelpers("items").mapGetters(["menuTypes"]),

    menuTypeSelected: {
      get() {
        return this;
      },
      set(val) {
        this.$store.dispatch("items/fetchItems", val);
      },
    },
  },
};
</script>
