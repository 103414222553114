<template>
  <!--Start Top Bar Component-->
  <v-card
    tile
    elevation="0"
    color="transparent"
    class="d-flex mx-auto justify-space-between align-center"
  >
    <div v-for="(stepItems, i) in mobileStepsItems" :key="i">
      <div class="d-flex align-center">
        <v-avatar
          style="z-index: 5;"
          size="35"
          :color="
            step > stepItems.step || step == 6
              ? stepItems.completeColor
              : stepItems.primaryColor
          "
        >
          <v-icon
            small
            :color="
              step > stepItems.step || step == 6
                ? stepItems.iconCompleteColor
                : stepItems.iconPrimaryColor
            "
          >
            {{
              step > stepItems.step || step == 6
                ? stepItems.primaryIcon
                : stepItems.primaryIcon
            }}
          </v-icon>
        </v-avatar>

        <v-progress-linear
          background-color="accent"
          color="primary"
          absolute
          :value="progressVal"
        ></v-progress-linear>
      </div>
    </div>
  </v-card>
  <!--End Top Bar Component-->
</template>

<script>
export default {
  name: "TopBarComponent",

  props: ["step", "progressVal"],

  data() {
    return {
      mobileStepsItems: [
        {
          step: 1,
          primaryColor: "accent",
          completeColor: "primary",
          iconPrimaryColor: "white",
          iconCompleteColor: "white",
          completeIcon: "mdi-check-bold",
          primaryIcon: "mdi-account-group",
          title: "Reservation Type",
        },
        {
          step: 2,
          primaryColor: "accent",
          completeColor: "primary",
          iconPrimaryColor: "white",
          iconCompleteColor: "white",
          completeIcon: "mdi-check-bold",
          primaryIcon: "mdi-calendar-clock",
          title: "Date & Time",
        },
        {
          step: 3,
          primaryColor: "accent",
          completeColor: "primary",
          iconPrimaryColor: "white",
          iconCompleteColor: "white",
          completeIcon: "mdi-check-bold",
          primaryIcon: "mdi-table-furniture",
          title: "Tables",
        },
        {
          step: 4,
          primaryColor: "accent",
          completeColor: "primary",
          iconPrimaryColor: "white",
          iconCompleteColor: "white",
          completeIcon: "mdi-check-bold",
          primaryIcon: "mdi-silverware-variant",
          title: "items",
        },
        {
          step: 5,
          primaryColor: "accent",
          completeColor: "primary",
          iconPrimaryColor: "white",
          iconCompleteColor: "white",
          completeIcon: "mdi-check-bold",
          primaryIcon: "mdi-account-details",
          title: "Customer Data",
        },
        {
          step: 6,
          primaryColor: "accent",
          completeColor: "primary",
          iconPrimaryColor: "white",
          iconCompleteColor: "white",
          completeIcon: "mdi-check-bold",
          primaryIcon: "mdi-check-all",
          title: "Confirmation",
        },
      ],
    };
  },
};
</script>
